import React from "react";
import { ExperienceData } from "../_components/Messages";
import {
  CourseFont,
  CourseFont2,
  CourseFont3,
  BoxTitle,
  BoxContent,
  CourseFont4,
} from "./Styles";

function Item({ Empresa, Año, Cargo, Detalle }) {
  return (
    <>
      <BoxContent>
        <BoxTitle>
          <div>
            <CourseFont><b>{Empresa}</b></CourseFont>
            <CourseFont4><i>{Cargo}</i></CourseFont4>
          </div>
          <CourseFont2>{Año}</CourseFont2>
        </BoxTitle>
        {Detalle && <CourseFont3>{Detalle}</CourseFont3>}
      </BoxContent>
    </>
  );
}

export default function Experience({ show }) {
  return (
    <div>
      {(show && (
        <div className="animate__animated animate__fadeIn">
          {ExperienceData.slice(0)
            .reverse()
            .map((e, index) => (
              <Item
                key={index}
                Empresa={e.Empresa}
                Año={e.Año}
                Cargo={e.Cargo}
                Detalle={e.Detalle}
              />
            ))}
        </div>
      )) || (
        <>
          {ExperienceData.slice(
            ExperienceData.length - 2,
            ExperienceData.length
          )
            .reverse()
            .map((e, index) => (
              <Item
                key={index}
                Empresa={e.Empresa}
                Año={e.Año}
                Cargo={e.Cargo}
              />
            ))}
        </>
      )}
    </div>
  );
}
