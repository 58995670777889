import React from "react";
import styled from "styled-components";
import { PageData } from "./Messages";
import UserData from "./UserData";
import Skills from "./Skills";
import Box from "./Box";

const LeftPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right:0.5rem;

  @media screen and (max-width:1440px) {
    max-width:800px;
    width:100%;
    margin-right:0;
    margin:0 auto;
    }
`;

export default function LeftPanel() {
  return (
    <>
      <LeftPanelContent>
        <UserData />
        <Box title={PageData.Skilltitle} content={Skills} button />
      </LeftPanelContent>
    </>
  );
}
