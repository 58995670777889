import React from "react";
import { EducationData } from "./Messages";
import {
  CourseFont,
  CourseFont2,
  CourseFont3,
  CourseFont4,
  BoxTitle,
  BoxContent,
} from "./Styles";

function Item({ Curso, Institucion, Detalle, Año }) {
  return (
    <>
      <BoxContent>
        <BoxTitle>
          <div>
            <CourseFont>
              <b>{Curso}</b>
            </CourseFont>
            <CourseFont4>
              <i>{Institucion}</i>
            </CourseFont4>
          </div>
          <CourseFont2>{Año}</CourseFont2>
        </BoxTitle>
        <CourseFont3>{Detalle}</CourseFont3>
      </BoxContent>
    </>
  );
}

export default function Education({ show }) {
  return (
    <div>
      {(show && (
        <div>
          {EducationData.slice(0)
            .reverse()
            .map((e, index) => (
              <Item
                key={index}
                Curso={e.Curso}
                Institucion={e.Institucion}
                Detalle={e.Detalle}
                Año={e.Año}
              />
            ))}
        </div>
      )) || (
        <>
          {EducationData.slice(EducationData.length - 2, EducationData.length)
            .reverse()
            .map((e,index) => (
              <Item
                key={index}
                Curso={e.Curso}
                Institucion={e.Institucion}
                Año={e.Año}
              />
            ))}
        </>
      )}
    </div>
  );
}
