import React, { useState } from "react";

import styled from "styled-components";

import { ThemeProvider } from "styled-components";
import { isMobile } from "react-device-detect";

// import Routes from "../src/_components/Routes";
import { LightTheme, DarkTheme, GlobalStyles } from "./_components/Themes";

import WebLayout from "./_layout/WebLayout";
import LoaderInitial from "./_components/LoaderInitial";
import { Switch } from 'antd';
import CookiesBanner from "./_components/CookieBanner";

import Icons from "./_components/Icons";




const ContaininerTheme = styled.div`
  position: fixed;
  top: ${isMobile ? "1.5rem" : "2rem"};
  right: ${isMobile ? "0rem" : "2rem"};
  z-index: 2;
  transform: ${isMobile ? "rotate(90deg)" : "none"};
  i{
    transform: ${isMobile ? "rotate(150deg);" : "none"};
  }
`;


// =============================================
// =============================================

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 7)
let defaultTheme = "dark"

function App() {

  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 2500);


  // Cookie function
  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      } else { }
    }
    return "false";
  }

  // Get cookies
  if (navigator.cookieEnabled) {
    const themeSelection = getCookie('themeSelection');
    if(themeSelection!=="false"){
      defaultTheme = themeSelection;
    }else{
      const sessionItem = sessionStorage.getItem('themeSession')
      if(sessionItem){
        defaultTheme = sessionItem;
      }
    }
    
  }

  const [theme, setTheme] = useState(defaultTheme);
  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    let currentStatus = getCookie('themeSelection');

    if (currentStatus !== "false") {
      console.log('se puede cargar');
      document.cookie = `themeSelection=${newTheme}; expires=${expirationDate}; path=/`;
    } else {
      sessionStorage.setItem('themeSession',newTheme)
    }
    
  };

  return (
    <>
      <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>
        <GlobalStyles />
        {show ? (
          <>
            <WebLayout className="animate__animated animate__fadeIn animate__slower" />
            <ContaininerTheme className="animate__animated animate__fadeIn animate__delay-1s">
              <Switch
                checkedChildren={<Icons name="light" />}
                unCheckedChildren={<Icons name="night" />}
                defaultChecked
                onClick={() => themeToggler()} />

            </ContaininerTheme>
            <CookiesBanner />
          </>
        ) : (
          <>
            <LoaderInitial />

          </>
        )}

      </ThemeProvider>

    </>
  );
}

export default App;
