import React from "react";
import { SkillsData } from "../_components/Messages";
import styled from "styled-components";
import { BoxContentSkills, Skillsfont } from "./Styles";

function getLevel({ width }) {
  return width;
}

const LevelWidth = styled.div`
  background: ${({ theme }) => theme.gradient};
  transition: 500ms;
  height: 0.35rem;
  border-radius: 0.3rem;
  width: ${getLevel}%;
  animation: Animate-HTML 4s;
    -webkit-animation: Animate-HTML 4s;
    -moz-animation: Animate-HTML 4s;
    -o-animation: Animate-HTML 4s;


    @keyframes Animate-HTML {
      from {
      width: 10px;
  }
  to {
    ${getLevel}%}
  }@-webkit-keyframes Animate-HTML {
      from {
      width: 10px;
  }
  to {
      width: 100%}
  }@-moz-keyframes Animate-HTML {
      from {
      width: 10px;
  }
  to {
      width: 100%}
  }@-o-keyframes Animate-HTML {
      from {
      width: 10px;
  }
  to {
      width: ${getLevel}%}


`;

const Level = styled.div`
  background: ${({ theme }) => theme.varlevel};
  height: 0.35rem;
  width: 100%;
  border-radius: 0.3rem;
  transition: 500ms;
  
`;

function Item({ type, level }) {
  return (
    <div>
      <BoxContentSkills>
        <Skillsfont> {type}</Skillsfont>
        <>
          <Level>
            <LevelWidth width={level} />
          </Level>
        </>
      </BoxContentSkills>
    </div>
  );
}

export default function Skills({ show }) {
  return (
    <div>
      {(show && (
        <>
          {SkillsData.map((e, index) => (
            <div key={index}>
              <Item type={e.type} level={e.level} />
            </div>
          ))}
        </>
      )) || (
        <>
          {SkillsData.slice(0, 4).map((e, index) => (
            <div key={index}>
              <Item type={e.type} level={e.level} />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
