import { React } from "react";
import { PorfolioSummary } from "../_components/Messages";
import { Link } from "react-router-dom";
import {
  IconWrapper,
  BoxContainerDetail,
  Subtitle,
  MainTitle,
  Subcard2,
  DetailParagraph,
  Subcard,
  Card,
  VisitJob,
  VisitCard,
  PictureJob2,
  Details,
} from "../_components/Styles";
import Icons from "../_components/Icons";
import { isMobile } from "react-device-detect";

function ArticlePage({ match }) {
  const name = match.params.name;
  const article = PorfolioSummary.find((article) => article.name === name);
  return isMobile ? (
    <>
      <>
        <div className="detailJob animate__animated animate__fadeInDown animate__fast">
          <BoxContainerDetail>
            <Card>
              <Link title="Back" aria-label="Back" to={`/`}>
                <IconWrapper>
                  <Icons name="navigateless" />
                </IconWrapper>
              </Link>
              <Subcard>
                <MainTitle>{article.Empresa}</MainTitle>
                <Subtitle>{article.Cliente}</Subtitle>
              </Subcard>
            </Card>

            <PictureJob2 src={article.Picture} alt={`${article.Cliente} - ${article.Empresa}`} title={`${article.Cliente} - ${article.Empresa}`}></PictureJob2>

            <Subcard2>
              <Subtitle>Country: </Subtitle>
              <Details>{article.Country}</Details>
            </Subcard2>
            <Subcard2>
              <Subtitle>Skills: </Subtitle>
              <Details>{article.Skills}</Details>
            </Subcard2>
            <Subcard2>
              <Subtitle>Technology used: </Subtitle>
              <Details>{article.Technology}</Details>
            </Subcard2>
            <Subcard2>
              <Subtitle>Software used: </Subtitle>
              <Details>{article.Software}</Details>
            </Subcard2>
            <Subcard2>
              <Subtitle>Types: </Subtitle>
              <Details>{article.Type}</Details>
            </Subcard2>
            <DetailParagraph>
              <Subtitle>Details: </Subtitle>
              <Details>{article.Detail}</Details>
            </DetailParagraph>
            <Subcard>
              {article.site && (
                <VisitCard title="Check live site" aria-label="Back">
                  <VisitJob
                    href={article.site}
                    rel="noreferrer"
                    target="_blank"
                    title="Check live site"
                    aria-label="Check live site"
                  >
                    Check Site
                  </VisitJob>
                </VisitCard>
              )}
              {article.site2 && (
                <VisitCard>
                  <VisitJob
                    href={article.site2}
                    rel="noreferrer"
                    target="_blank"
                    title="Check Repo"
                    aria-label="Check Repo"
                  >
                    Check Repo
                  </VisitJob>
                </VisitCard>
              )}
            </Subcard>
          </BoxContainerDetail>
        </div>
      </>
    </>
  ) : (
    <div className="detailJob animate__animated animate__fadeInDown animate__fast">
      <BoxContainerDetail>
        <Card>
          <Link
            title="Back"
            aria-label="Back"
            to={`/`}
            style={{ textDecoration: "none" }}
          >
            <IconWrapper>
              <Icons name="navigateless" />
            </IconWrapper>
          </Link>
          <Subcard>
            <MainTitle>{article.Empresa}</MainTitle>
            <Subtitle>{article.Cliente}</Subtitle>
          </Subcard>
        </Card>
        <PictureJob2 src={article.Picture} alt={`${article.Cliente} - ${article.Empresa}`} title={`${article.Cliente} - ${article.Empresa}`}></PictureJob2>



        <Subcard2>
          <Subtitle>Country: </Subtitle>
          <Details>{article.Country}</Details>
        </Subcard2>
        <Subcard2>
          <Subtitle>Skills: </Subtitle>
          <Details>{article.Skills}</Details>
        </Subcard2>
        <Subcard2>
          <Subtitle>Technology used: </Subtitle>
          <Details>{article.Technology}</Details>
        </Subcard2>
        <Subcard2>
          <Subtitle>Software used: </Subtitle>
          <Details>{article.Software}</Details>
        </Subcard2>
        <Subcard2>
          <Subtitle>Types: </Subtitle>
          <Details>{article.Type}</Details>
        </Subcard2>
        <DetailParagraph>
          <Subtitle>Details: </Subtitle>
          <Details>{article.Detail}</Details>
        </DetailParagraph>
        <Subcard>
          {article.site && (
            <VisitCard title="Check live site" aria-label="Back">
              <VisitJob
                href={article.site}
                rel="noreferrer"
                target="_blank"
                title="Check live site"
                aria-label="Check live site"
              >
                Check Site
              </VisitJob>
            </VisitCard>
          )}
          {article.site2 && (
            <VisitCard>
              <VisitJob
                href={article.site2}
                rel="noreferrer"
                target="_blank"
                title="Check Repo"
                aria-label="Check Repo"
              >
                Check Repo
              </VisitJob>
            </VisitCard>
          )}
        </Subcard>
      </BoxContainerDetail>
    </div>
  );
}

export default ArticlePage;
