import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import Lottie2 from "../_components/Lottie2";

import Icons from "./Icons";

// **********MAINCARD************//




export const MainCard = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  transition: 500ms;
  font-weight: bold;
  text-align: center;
  margin: 0.2rem;
  font-size: 1.5rem;
  word-break: break-word;
`;
export const MainCard2 = styled(MainCard)`
  color: ${({ theme }) => theme.mainFontColorUser};
  font-weight: bold;
  justify-self: center;
  font-size: 1rem;
  padding: 0.5rem;
  word-break: break-word;
  width: auto;
  border-radius: 0.5rem;
  transition: 500ms;
`;

export const MainCard3 = styled(MainCard2)`
  color: ${({ theme }) => theme.mainFontColor};
  transition: 500ms;
  font-weight: normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  word-break: break-word;
  transition: 500ms;
`;

// **********SKILLS************//
export const Titles = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: bolder;
  margin: 0 0.2rem 0 0.2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  word-break: break-word;
  transition: 500ms;
 `;



export const SubTitles = styled.h3`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  padding-bottom: 1rem;
  word-break: break-word;
  transition: 500ms;
`;

export const Values = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  text-align: end;
  word-break: break-all;
  transition: 500ms;
`;

// **********EDUCATION************//

export const CourseFont = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  margin: 0.2rem;
  font-size: 0.8rem;
  word-break: break-word;
  text-transform: uppercase;
  font-weight: bolder;
`;

export const Skillsfont = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  word-break: break-word;
`;

export const CourseFont2 = styled(CourseFont)`
  margin: 0.2rem;
  font-size: ${isMobile ? "0.7rem;" : "0.8rem;"}
  word-break: break-word;
  font-weight: normal;
  text-align:right;
`;
export const CourseFont3 = styled.p`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: normal;
  font-size: 0.8rem;
  word-break: break-word;
  text-align: justify;
  word-spacing: 0.1rem;
  margin:0;
`;

export const CourseFont4 = styled(CourseFont2)`
  margin: 0.2rem;
  font-size: 0.8rem;
  word-break: break-word;
  text-align: left;
`;

export const ParagraphText = styled.h1`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: 400;
  margin: 1rem 0;
  font-size: ${isMobile ? "1rem;" : "0.8rem;"}
  text-align: justify;
  line-height: 20px;
  word-break: break-word;
  word-spacing: 0.1rem;
  `;

///////////FORMS/////////////////
export const BoxTitle = styled.div`
  display: grid;
  grid-template-columns: 7fr 4fr;
  column-gap: 1rem;
  word-break: break-all;  
  margin-top: 0.5rem;
  
}
`;
export const BoxContent = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.line};
  align-items: center;
  column-gap: 0.5rem;
  padding: 5px 0 5px 0;
  word-break: break-word;
  transition: 500ms;

  h2 {
    align-self: flex-start;
    justify-self: flex-end;
  }
`;
export const BoxContentSkills = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  padding:5px 0;
  word-break: break-word;

  h2 {
    align-self: flex-start;
    justify-self: flex-end;
  }
`;

export const BoxPorfolio = styled.div`
  background-color: transparent;
  width 100%;
  margin-bottom: 0.5rem;
  padding: 10px;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.mainFontColor};
  transition: 500ms;
  text-align: center;
`;

///////////FOOTER//////////////

export const Footer = styled.div`
position:relative;
z-index: 1999;
text-align: center;
font-size: 0.7rem;
font-weight: bold;
border-radius:0.5rem;
background: ${({ theme }) => theme.box};
box-shadow: ${({ theme }) => theme.shadow};
color: ${({ theme }) => theme.mainFontColor};
font-family: ${({ theme }) => theme.mainFont};
transition: 500ms;
width:100%;
max-width:1600px;
padding:5px 0;
margin:0 auto;
display:flex;
flex-wrap:wrap;
justify-content: center;
align-items: center;
padding:10px 0;
.separator{
  margin: 0 10px;
}



    @media screen and (max-width:720px) {
      margin:10px auto;
      max-width:100%;
    }
`;

/////////DESKTOP BACK////////
const BackDesktopComponent = styled.div`
  
  transition: 500ms;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  z-index: 1;
  width: 100%;
  
  .innerContainer{
    display:flex;
    flex-direction:column;
    align-items: center;
    padding-bottom:2rem;
  }
  .containerFooter{
    padding: 0 1rem 1rem 1rem;
    width:100%;
    @media screen and (max-width:1440px) {
      max-width: 800px;
      padding:0;
      width:95%
        }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bar};
    border-radius: 25px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bar2};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.icons};
    transition: 0.2s;
  }
  z-index: 1999;
  &:after{
    background-color: ${({ theme }) => theme.box};
    transition: 500ms;
  }
`;

export const MainBackground = styled.div`
background-color: ${({ theme }) => theme.background};
transition: 500ms;
`

export function BackgroundDesktop({ children, oneAnimation, twoAnimations }) {
  return (
    <>
      <BackDesktopComponent>
        {" "}
        <div className="innerContainer">{children}</div>
        {oneAnimation && <Lottie2 />}
        {twoAnimations && <></>}
      </BackDesktopComponent>
    </>
  );
}

//////////MOBILE BACK//////////

const BackgroundComponent = styled.div`
  background-color: ${({ theme }) => theme.background};
  transition: 500ms;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  padding-bottom:1rem;

`;


export function BackgroundMobile({ children, oneAnimation, twoAnimations }) {
  return (
    <>
      <BackgroundComponent>
        {" "}
        <div>{children}</div>
        {oneAnimation && <Lottie2 />}
        {twoAnimations && <></>}
      </BackgroundComponent>
    </>
  );
}

export const PaddingTextMobile = styled.div`
  align-items: center;
  margin: 6rem 2rem 3rem 2rem;
  line-height: 20px;
`;
export const PaddingSkills = styled.div`
  align-items: center;
  margin-bottom: 1rem;
`;

const TopBarComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  text-align: initial;
  align-items: center;
  background: ${({ theme }) => theme.boxmobile};
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 10px;
  position: fixed;
  top: 0;
  z-index: 1;

  p {
    color: ${({ theme }) => theme.mainFontColor};
    font-family: ${({ theme }) => theme.mainFont};
    
    &:after{
      transition: 500ms;
      font-weight: bolder;
      margin: 0 0.2rem 0 0.2rem;
      font-size: 1.2rem;
      text-transform: uppercase;
      word-break: break-all;
      transition: 500ms;
      color: ${({ theme }) => theme.mainFontColor};
      font-family: ${({ theme }) => theme.mainFont};
    }

  }
`;

export function TopBarMobile({ children }) {
  return (
    <TopBarComponent>
      <Link to="/" rel="noreferrer">
        <Icons name="prev" />
      </Link>
      <p>{children}</p>
    </TopBarComponent>
  );
}

export const ThemeIcons = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 0.5rem;
  margin-right: 1rem;


`;

////////////EXP//////////////////
///BUTTONS MOBILE///////////

export const MobileText = styled.a`
   justify-self: center;
   word-break: break-word;
   width: auto;
   font-size:1rem:
   border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  &:active{
    background: ${({ theme }) => theme.background};
    
    }
`;

export const MobileBoxText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  width: 80%;
  padding: 4px 0;
  background: linear-gradient(to right, transparent, #448add, transparent);
  transition: 400ms all;
  justify-self: center;
  margin: 0.6rem 1rem;
  text-align: center;
  gap: 7px;
  z-index: 2;
  a {
    text-decoration: none;
    font-weight: bold;
    color: white;
  }
  i {
    font-size: 10px;
  }
`;

////MOBILE THEMES///

export const IconButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  border-radius: 100rem;
  justify-content: center;
  margin-bottom: 0.2rem;
  border: none;
  width: 2.6rem;
  height: 2.6rem;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.icons};
  box-shadow: ${({ theme }) => theme.iconShadows};

  &:hover {
    cursor: pointer;
    background: #0b3f69;
    transition: 500ms;
    cursor: pointer;
  }
   {
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      background: rgba(255, 255, 255, 0.9);
      display: block;
      position: absolute;
      border-radius: 50%;
      padding-top: 240%;
      padding-left: 240%;
      margin-top: -120%;
      margin-left: -120%;
      opacity: 0;
      transition: all 1s;
    }

    &:active:after {
      padding-top: 0;
      padding-left: 0;
      margin-top: 0;
      margin-left: 0;
      opacity: 1;
      transition: 0s;
    }
  }
  color: white;
  cursor: pointer;
`;

export const IconButtonWrapperMobileAll = styled(IconButtonWrapper)`
  flex-direction: columns;
`;

///////////PORTFOLIOWEB/////////////

export const ContainerCards = styled.div`
order:1;
@media screen and (max-width:1440px) {
  order:3;
}
`

export const ContainerPortfolioWeb = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-decoration: none;
  align-items: center;
  
  @media screen and (max-width:1440px) {
  max-width: 800px;
  width: 100%;
  margin:0 auto;

  }

    @media screen and (max-width:720px) {
    grid-template-columns: 1fr;
    }

`;
////PORTFOLIOMOBILE////
export const ContainerPortfolioMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 6rem 1rem 1rem 1rem;
`;



export const BoxContainer = styled.div`
background-color: ${({ theme }) => theme.box};
color: ${({ theme }) => theme.mainFontColor};
box-shadow:${({ theme }) => theme.shadow};
width 100%;
@media screen and (max-width:1440px) {
width:100%;
  }

height: auto;
transition: 500ms;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 0.5rem;
  text-decoration: none;
  
  h3{
    margin:0;
    text-decoration: none;
  }
  
  transition: 500ms;
  height: auto;
  background-color: ${({ theme }) => theme.box};
  color: ${({ theme }) => theme.mainFontColor};
  box-shadow:${({ theme }) => theme.shadow};
`;

export const Content = styled.h3`
  color: ${({ theme }) => theme.mainFontColor};
  transition: 500ms;
  text-decoration: none;
`;

export const BoxContainerPortfolioMobile = styled(BoxContainer)`
display:none;
@media screen and (max-width:1440px) {
  display:flex;
  margin:0 0 15px 0;
}
`;

export const TitlesPortfolio = styled(Titles)`
 `;

export const IconWrapper = styled.div`
  border-radius: 100rem;
  border: 0;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
  i{
    transition: 500ms;
    color: ${({ theme }) => theme.background};
    
  }



`;

export const BoxContainerPortfolio = styled.div`
  background-color: ${({ theme }) => theme.box};
  margin:0rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.mainFontColor};
  box-shadow:${({ theme }) => theme.shadow};
  overflow:hidden;
  img{
    transition:1s;
    opacity: 80%;
  }
  text-decoration: none;
        &:hover{
        transition: 500ms;
        img{
          opacity: 100%;
        }
        background-color: ${({ theme }) => theme.boxhover};        
    }  

    @media screen and (max-width:1440px) {
      width:100%;
    }
`;

export const HeaderJob = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  align-items: center;
  padding-right: 0.5rem;
  `;

export const BoxContainerDetail = styled.div`
transition: 500ms;
width ${isMobile ? "100%" : "100%"};
border-radius:0.5rem;
opacity: 95%;
padding-bottom: 1rem;
margin: ${isMobile ? "0.5rem 0 0 0.6rem;" : ""};
gap:2rem;
height: ${isMobile ? "auto" : ""};
background-color: ${({ theme }) => theme.box};
color: ${({ theme }) => theme.mainFontColor};
box-shadow:${({ theme }) => theme.shadow};
@media screen and (max-width:1440px) {
  max-width: 800px;
  width: 100%;
}

`;

export const MainTitle = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: bold;
  text-decoration: none;
  margin: 0.2rem;
  font-size: 0.9rem;
  word-break: break-word;
  padding: 1rem 0 0 1rem;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  font-weight: Normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  word-break: break-word;
  padding: 0rem 0.2rem 0.5rem 1rem;
  line-height: 20px;;
`;

export const Details = styled.h2`
  font-weight: Normal;
  margin: 0.2rem;
  font-size: 0.8rem;
  word-break: break-word;
  padding: 5px 0.2rem 5px 1rem;
  line-height: 20px;
  text-align: right;
  color: ${({ theme }) => theme.mainFontColor};
  font-family: ${({ theme }) => theme.mainFont};
  transition: 500ms;
  

`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  line-height: 20px;
  margin-bottom: 0.5rem;
  padding-left: ${isMobile ? "0.5rem" : ""};
  padding-left: 1rem;
`;

export const Subcard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Subcard2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 20px;
`;
export const DetailParagraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 20px;
  border-top: 1px solid ${({ theme }) => theme.line};
  margin: 10px 8px 0 8px!important;
  padding-top:10px;
  
  
  h2{
    padding:0rem 0.2rem 0rem 1rem;
    text-align: justify;
  }
  h2:first-of-type {
    font-weight:900;
  }
`;

export const VisitCard = styled.div`
  margin: 1rem 0;
  align-self: center;
  width: ${isMobile ? "70%;" : "40%;"}
  height: 1.6rem;
  display: flex;
  padding: ${isMobile ? "0.5rem 0" : "1rem 0"};
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, transparent, #448add, transparent);
  transition: 400ms all;
  &:hover {
    transition: 400ms all;
    cursor: pointer;
    width: ${isMobile ? "90%;" : "70%;"}
  }
`;

export const VisitJob = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.mainFontColorUser};
  transition-timing-function: ease-in-out;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  &:hover {
    color: white;
  }
`;

export const PictureJob1 = styled.img`
  width: ${isMobile ? "100%" : "100%"};
  height: ${isMobile ? "100%" : "100%"};
  margin-bottom: 10px;
`;
export const PictureJob2 = styled.img`
  width: ${isMobile ? "100%" : "100%"};
  height:100%;
  margin-bottom: 10px;
`;

export const BackButton = styled.div`
text-decoration:none;
display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  boder: none;
  background: ${({ theme }) => theme.icons};
  margin-top: 0.5rem;
  transition: 500ms;
  &:hover {
    cursor: pointer;
    background: #72b5dc;
    transition: 500ms;
  }
  border-radius: 100rem;
  box-shadow: ${({ theme }) => theme.iconShadows};
  svg {
    display: flex;
    align-items: center;
    fill: white;
    width: 1.4rem;
    height: 1.4rem;
    justify-content: center;
    align-items: center;
  }
   {
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      background: rgba(255, 255, 255, 0.9);
      display: block;
      position: absolute;
      border-radius: 50%;
      padding-top: 240%;
      padding-left: 240%;
      margin-top: -120%;
      margin-left: -120%;
      opacity: 0;
      transition: all 1s;
    }

    &:active:after {
      padding-top: 0;
      padding-left: 0;
      margin-top: 0;
      margin-left: 0;
      opacity: 1;
      transition: 0s;
    }
  }
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  
  
`;

////WAPP HOME MOBILE////
export const Wapp = styled.div`
  position: fixed;
  top: 20rem;
  z-index: 3;
  right: 0.9rem;
`;

////WAPP Pages MOBILE////
export const WappPages = styled.div`
  position: fixed;
  top: 0.5rem;
  z-index: 3;
  right: 0.9rem;
`;
////WAPP Pages DESKTOP////
export const WappPages2 = styled.div`
  position: fixed;
  bottom: 5rem;
  z-index: 3;
  right: 2rem;
`;
