import React from "react";
import styled from "styled-components";

import Lottie5 from "../_components/Lottie5";
import Lottie7 from "../_components/Lottie7";
import gioindex from "../_images/gioindex-reduced.png";

const LoaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #212121;
  height: 100vh;
  justify-content: center;
`;

const Image = styled.div`
  background-image: url(${gioindex});
  background-size: contain;
  background-repeat: no-repeat;
  width: 15rem;
  height: 25rem;
  position:relative,
  z-index:1999;
`;

const Messgae = styled.h2`
color: white;
text-align:center;
margin:0;
text-transform:uppercase;
font-weight:400;
font-size:15px;
font-family: 'Montserrat',sans-serif;
animation: Animate-text 5s;
position:relative;
top:0;
z-index:-9;

@keyframes Animate-text {
  from {
  opacity: 0;
}

to {
  opacity:1;

}

to {

  top:2%;
}

`;



export default function LoaderInitial() {

  let messageText = "Welcome . . .";

  if (document.cookie.split(';').some((item) => item.trim().startsWith('themeSelection='))) {
    messageText= "Welcome back . . ."
  } 
  return (
    <LoaderComponent className="animate__animated animate__fadeIn">
      <Image className="animate__animated animate__fadeInIn" alt="Jorge Ruanova - SSR Frontend Developer">
        <Lottie7 className="animate__animated animate__fadeInIn animate__slower" />
      </Image>

      <div className="animate__animated animate__fadeIn">
        <Lottie5 />
      </div>
      <Messgae>{messageText}</Messgae>
    </LoaderComponent>
  );
}
