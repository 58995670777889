import React from "react";
import { Footer } from "../_components/Styles";

export default function FooterData() {
  return (
    <Footer>
      2018 - {new Date().getFullYear()}
      <div className="separator">|</div> 
      <div>Giorgio Ruanova.</div>
      <div className="teste"> All Rights Reserved.</div>
    </Footer>
  );
}
